import React from "react";
import { useEffect, useState } from "react";

import axios from 'axios'




const AppEngine = (props) => {

  //state variables
  // const [status, setStatus] = useState("Welcome to the MobiPad IDO / Fairlaunch Page.");

  const [totalInvested, setTotalInvested] = useState(0);
  const [targetedRaise, setTargetedRaise] = useState(0);


  const [idoData, setIdoData] = useState([]);

  const [idoContractAddr] = useState(props.idoContractAddr);
  const [depositAddr] = useState(props.depositAddrStr);
  const [depositAddrCopied, setDepositAddrCopied] = useState(false);


  const [saleNotStart, setSaleNotStart] = useState(false);
  const [saleEnded, setSaleEnded] = useState(false);

  const [countdownString, setCountdownString] = useState("");
  const [countdownDays, setCountdownDays] = useState("");
  const [countdownHours, setCountdownHours] = useState("");
  const [countdownMinutes, setCountdownMinutes] = useState("");
  const [countdownSeconds, setCountdownSeconds] = useState("");



  // load DB Data // called only once

  useEffect(() => {
    // axios.get('http://localhost:8000/app-api/' + idoContractAddr)
    // axios.get('https://beta.mobipad.io/app-api/' + idoContractAddr)
    axios.get('https://mobipad.io/app-api/' + idoContractAddr)
      .then((res) => {
        setIdoData(res.data[0]);
        setTotalInvested(res.data[0].total_invested);
        setTargetedRaise(res.data[0].hardcap);
      })
      .catch((err) => {
        console.log("An HTTP error occured");
      })


    const postData = {
      "id": 1,
      "jsonrpc": "2.0",
      "method": "getBalance",
      "params":
        [
          depositAddr
        ]
    };
    axios.post('https://solana-mainnet.g.alchemy.com/v2/' + process.env.REACT_APP_ALCHEMY_API_KEY, postData)
      .then((res) => {
        let depositAddrBal = Number(res.data.result.value / 1e9);
        
        if (idoData.total_invested && Number(idoData.total_invested) < depositAddrBal) {
          setTotalInvested(depositAddrBal);
          // set total invested to cloud if it has changed
          if (idoData.total_invested !== depositAddrBal && depositAddrBal !== 0) {
            // axios.get('http://localhost:8000/app-api/' + idoContractAddr + '/' + depositAddrBal);
            // axios.get('https://beta.mobipad.io/app-api/' + idoContractAddr + '/' + depositAddrBal);
            axios.get('https://mobipad.io/app-api/' + idoContractAddr + '/' + depositAddrBal);
          }
        }
      })
      .catch((err) => {
        console.log("An HTTP error occured");
      })
    // eslint-disable-next-line
  }, [idoContractAddr]);



  //process countdown dependent in idoData
  useEffect(() => {
    processCountdown();
    saleActiveInfo();
    // eslint-disable-next-line
  }, [idoData]);


  function saleActiveInfo() {
    if (idoData.start) {
      var idoStart = new Date(idoData.start + "  UTC").getTime();
      var idoEnd = new Date(idoData.end + "  UTC").getTime();
      var now = new Date().getTime();
      if (idoStart > now) {
        // Not Start
        setSaleNotStart(true);
      }
      if (idoEnd < now) {
        // End
        setSaleEnded(true);
      }
    }
  }

  // Countdown
  function processCountdown() {
    if (idoData.start) {

      // Update the count down every 1 second
      var x = setInterval(function () {

        // Set the date we're counting down to
        var countDownDate = new Date(idoData.start + "  UTC").getTime();
        // var countDownDate = new Date("Jan 5, 2024 15:37:25").getTime();

        // Get today's date and time
        var now = new Date().getTime();

        // Find the distance between now and the count down date
        var distance = countDownDate - now;
        setCountdownString("Sale Starts In:");

        if (distance <= 0) {
          countDownDate = new Date(idoData.end + "  UTC").getTime();
          now = new Date().getTime();
          distance = countDownDate - now;
          setCountdownString("Sale Ends In:");
        }

        // Time calculations for days, hours, minutes and seconds
        var days = Math.floor(distance / (1000 * 60 * 60 * 24));
        var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);

        // Display the result in the element with id="demo"
        setCountdownDays(days);
        setCountdownHours(hours);
        setCountdownMinutes(minutes);
        setCountdownSeconds(seconds);

        // If the count down is finished, write some text
        if (distance < 0) {
          clearInterval(x);

          setCountdownDays(0);
          setCountdownHours(0);
          setCountdownMinutes(0);
          setCountdownSeconds(0);
        }
      }, 1000);
    } else {
      setCountdownDays(0);
      setCountdownHours(0);
      setCountdownMinutes(0);
      setCountdownSeconds(0);
    }
  }


  const onCopyPressed = async () => { //TODO: implement
    navigator.clipboard.writeText(depositAddr);
    setDepositAddrCopied(true);
  };

  useEffect(() => {
    if (depositAddrCopied) {
      const timer = setTimeout(() => {
        setDepositAddrCopied(false);
      }, 5000); // 5000 milliseconds = 5 seconds

      // Clear the timeout if the component unmounts or if depositAddrCopied changes
      return () => clearTimeout(timer);
    }
  }, [depositAddrCopied]);

  //the UI of our component
  return (

    <>




      <div className="main_wrapper">
        <header className="header">
          <div className="container">
            <div className="d-flex align-items-center">
              <div className="logo">
                <a href="https://mobipad.io/launchpad">
                  <img src="images/logo.png" alt="" className="d-none d-md-block" />
                  <img
                    src="images/logo-mobile.png"
                    alt=""
                    className="d-block d-md-none"
                  />
                </a>
              </div>
              <div className="header-right d-flex align-items-center justify-content-center">
                <div
                  className="offcanvas offcanvas-start"
                  tabIndex={-1}
                  id="offcanvasExample"
                  aria-labelledby="offcanvasExampleLabel"
                >
                  <div className="offcanvas-header d-lg-none justify-content-between p-0">
                    <div className="offcanvas-header-logo">
                      <img src="images/logo-mobile.png" alt="" />
                    </div>
                    <div
                      className="header-close"
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                    >
                      <i className="bi bi-x-lg" />
                    </div>
                  </div>
                  <div className="offcanvas-body">
                    <nav className="d-lg-none">
                      <ul className="navbar-nav navmenus">
                        <li className="nav-item">
                          <a href="https://mobipad.io/" className="nav-link">
                            Home
                          </a>
                        </li>
                        <li className="nav-item">
                          <a href="https://staking.mobipad.io" className="nav-link">
                            Staking
                          </a>
                        </li>
                      </ul>
                    </nav>
                    <div className="user-auth ms-auto">
                      <div className="d-flex align-items-center">
                        <ul className="d-lg-flex align-items-center">
                          <li>
                            <a href="https://mobipad.io/">
                              <img src="images/icon-home.png" alt="" />
                            </a>
                          </li>
                          <li>
                            {/* eslint-disable-next-line */}
                            <a href="" className="btn-primary text-uppercase btn-bsc">
                              <img src="images/icon-solana.png" alt="" /> SOLANA
                            </a>
                          </li>

                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <button
                className="btn d-lg-none bg-transparent border-0 p-0"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasExample"
                aria-controls="offcanvasExample"
              >
                <span className="mobile-nav">
                  <img src="images/menu.svg" alt="" />
                </span>
              </button>
            </div>
          </div>
        </header>
        <img src="./images/shap-top-center-2.png" alt="" className="shap-headertop" />
        <img
          src="./images/mob-top-banner.png"
          alt=""
          className="mob-shap-headertop"
        />




        <main className="main">
          <div className="sbm-top-banner">
            <div className="container">
              <a href="https://mobipad.io/launchpad" className="backbtn">
                <i className="bi bi-arrow-left" /> BACK
              </a>
              <div className="row">
                <div className="col-md-6">
                  <div className="inner-Banner">
                    <h1>{idoData.name}</h1>
                    <p>
                      {idoData.f_detail}
                    </p>
                    <div className="cis-rangebox2">
                      <div className="rangebox-wrap2">
                        <span className="range-dsn" style={{ width: `${(totalInvested / targetedRaise) * 100}%` }}>
                          <b>{totalInvested}</b>
                        </span>
                      </div>
                      <span className="num_per">{targetedRaise}</span>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="countdown-wrap size-xl">
                    <h4>{countdownString}</h4>
                    <div className="countdown">
                      <ul>
                        <li>
                          <span className="timeinfo">
                            <span className="days">{countdownDays}</span>
                            days
                          </span>
                        </li>
                        <li>
                          <span className="timeinfo">
                            <span className="hours">{countdownHours}</span>
                            Hours
                          </span>
                        </li>
                        <li>
                          <span className="timeinfo">
                            <span className="minutes">{countdownMinutes}</span>
                            Minutes
                          </span>
                        </li>
                        <li>
                          <span className="timeinfo">
                            <span className="seconds">{countdownSeconds}</span>
                            Seconds
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="sbm-card-wrap">
            <div className="container">
              <div className="row">
                <div className="col-md-6 mb-4">
                  <div className="cyan-box">
                    <div className="v-centered">
                      <ul className="list-leftrightcontent">
                        <li>
                          <span>Sale Type</span>
                          <span>{idoData.type}</span>
                        </li>
                        <li>
                          <span>Token Name</span>
                          <span>{idoData.name}</span>
                        </li>
                        <li>
                          <span>Token Symbol</span>
                          <span>{idoData.ticker}</span>
                        </li>
                        <li>
                          <span>Total Supply</span>
                          <span>{idoData.total_supply} {idoData.ticker}</span>
                        </li>
                        {idoData.type === "IDO" ?
                          <li>
                            <span>Tokens For Presale</span>
                            <span>{idoData.tokensale_supply} {idoData.ticker}</span>
                          </li>
                          :
                          <li>
                            <span>Fairlaunch Pool Supply</span>
                            <span>{idoData.tokensale_supply} {idoData.ticker}</span>
                          </li>
                        }
                        <li>
                          <span>{idoData.type === "IDO" ? "Total Raise (All Rounds)" : "Target Raise"}</span>
                          <span>{idoData.total_raise} SOL</span>
                        </li>
                        <li>
                          <span>Platform Allocation</span>
                          <span>{targetedRaise} SOL</span>
                        </li>
                        <li>
                          <span>Presale Start Time (UTC) (Y-M-D)</span>
                          <span>{idoData.start}</span>
                        </li>
                        <li>
                          <span>Presale End Time (UTC) (Y-M-D)</span>
                          <span>{idoData.end}</span>
                        </li>
                        <li>
                          <span>Vesting Details</span>
                          <span>{idoData.vesting}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 mb-4">
                  <div className="cyan-box">

                    <div className="v-centered">
                      <a href="https://staking.mobipad.io" className="float-end">
                        <svg
                          width={35}
                          height={36}
                          viewBox="0 0 35 36"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="17.5" cy="17.5781" r="17.5" fill="#00C3C8" />
                          <path
                            d="M14.9062 19.1355L23.9993 10.0425M23.9993 10.0425H19.1853M23.9993 10.0425V14.8565"
                            stroke="black"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                          />
                          <path
                            d="M15.9768 12.1819H11.6279C11.0756 12.1819 10.6279 12.6297 10.6279 13.1819V21.8797C10.6279 22.432 11.0756 22.8797 11.6279 22.8797H21.3954C21.9477 22.8797 22.3954 22.432 22.3954 21.8797V18.6006"
                            stroke="black"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                          />
                        </svg>
                      </a>
                      <h2>Stake Requirement</h2>
                      <ul className="list-leftrightcontent">
                        <li>
                          <span>No stake requirements currently for Solana projects</span>
                        </li>
                        <li>
                          <span className="text-clr-1">
                            * There are some benefits for stakers however. This will be announced
                          </span>
                        </li>
                      </ul>
                      <div className="row">


                        <div className="col-md-12">

                          <p className="checkinfo">
                            <svg
                              width={16}
                              height={17}
                              viewBox="0 0 16 17"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <circle cx={8} cy="8.57812" r={8} fill="green" />
                              <path
                                d="M3 8.07812L7.5 12.0781L13 5.57812"
                                stroke="black"
                              />
                            </svg>
                            {" "}
                            You are eligible to invest in this {idoData.type}
                          </p>


                        </div>

                      </div>
                    </div>


                  </div>
                </div>
                <div className="col-md-6 col-lg-6 mb-4">
                  <div className="cyan-box h-auto mb-4">
                    <div className="v-centered">



                      <h4 className="mb-4">Sale Details</h4>

                      <ul className="list-leftrightcontent">
                        <li>
                          <span>{idoData.type === "IDO" ? "Token Price" : "Total Invested"}</span>
                          {idoData.type === "IDO" ?
                            <span>{idoData.price} SOL</span>
                            :
                            <span>{totalInvested} SOL</span>
                          }
                        </li>
                        <li>
                          {idoData.type === "IDO" ?
                            <span>Rate per 1 SOL</span>
                            :
                            <span>Current Rate in SOL</span>
                          }
                          {idoData.type === "IDO" ?
                            <span>{1 / idoData.price} {idoData.ticker} / SOL</span>
                            :
                            <span>{(totalInvested / idoData.tokensale_supply).toFixed(10)} SOL</span>
                          }
                        </li>
                        <li>
                          {idoData.type === "IDO" ?
                            <>
                              <span>Minimum Buy</span>
                              <span>0.1 SOL</span>
                            </>
                            :
                            <>
                              <span>Minimum Buy</span>
                              <span>0.1 SOL</span>
                            </>
                          }
                        </li>
                        <li>
                          {idoData.type === "IDO" ?
                            <>
                              <span>Maximum Buy</span>
                              <span>100 SOL</span>
                            </>
                            :
                            <>
                              <span>Maximum Buy</span>
                              <span>65 SOL</span>
                            </>
                          }
                        </li>
                      </ul>



                    </div>
                  </div>



                  <div className="cyan-box h-auto">
                    <div className="v-centered">
                      <div className="cyan-box-dsn-1">
                        <h3 className="mb-5">Claim Section</h3>



                        <p>The token will be sent to the address you sent SOL from</p>

                      </div>
                    </div>
                  </div>



                </div>




                <div className="col-md-12 col-lg-6 mb-4">
                  <div className="cyan-box">
                    <div className="v-centered">


                      <h4 className="mb-4">Contribute</h4>
                      {/* <p className="checkinfo">Contribute</p> */}


                      {!saleNotStart && !saleEnded &&
                        <>
                          <p className="text-clr-1">
                            * STEP 1:
                            <br />
                            Copy the deposit address below.
                          </p>


                          {/* <input
                          id="depositAddress"
                          type="text"
                          className="form-control user-select-all"
                          value={depositAddr}
                          readOnly
                        /> */}

                          <div className="text-center mt-5">
                            <span
                              className="py-3 px-3 user-select-all code_text"
                              style={{ color: "wheat", backgroundColor: "#0E1C32", border: "1px solid wheat", borderRadius: "50px" }}
                            >
                              {depositAddr}
                            </span>
                          </div>

                          {/* {depositAddrCopied &&
                          <br />
                        } */}

                          <div
                            className={depositAddrCopied ? "text-center mt-4 mb-2 show" : "text-center m-0 p-0 fade"}
                          >

                            <p className="p-0 m-0">
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-check2-all" viewBox="0 0 16 16">
                                <path d="M12.354 4.354a.5.5 0 0 0-.708-.708L5 10.293 1.854 7.146a.5.5 0 1 0-.708.708l3.5 3.5a.5.5 0 0 0 .708 0zm-4.208 7-.896-.897.707-.707.543.543 6.646-6.647a.5.5 0 0 1 .708.708l-7 7a.5.5 0 0 1-.708 0" />
                                <path d="m5.354 7.146.896.897-.707.707-.897-.896a.5.5 0 1 1 .708-.708" />
                              </svg> Copied !
                            </p>
                          </div>

                          <div className="text-center mt-2 mb-4">
                            <button
                              className="btn btn-info"
                              id="copyDepositAddr"
                              onClick={onCopyPressed}
                            >
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-copy" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z" />
                              </svg> Copy Address
                            </button>

                          </div>

                          <hr />

                          <p className="text-clr-1">
                            ** STEP 2:
                            <br />
                            Send your SOL investment to the deposit address.
                          </p>

                          <div className="col-md-12 my-3 text-center">
                            <small className="text-danger">
                              Do not send from an exchange address
                            </small>
                          </div>

                          <hr />

                          <p className="text-clr-1">
                            *** STEP 3:
                            <br />
                            You will get your Token when the sale ends.
                          </p>

                          <div className="col-md-12 my-3 text-center">
                            <small className="text-danger">
                              Keep the wallet you sent from safe, you will ONLY receive tokens on the wallet
                            </small>
                          </div>
                        </>
                      }


                      {saleNotStart &&
                        <>
                          <p className="text-clr-1">
                            * Sale hasn't started yet, when sale starts you will see the deposit address.
                          </p>
                        </>
                      }


                      {saleEnded &&
                        <>
                          <p className="text-clr-1">
                            * Sale Ended. You will receive tokens on the wallet you sent SOL from.
                          </p>
                        </>
                      }






                    </div>
                  </div>
                </div>



                {/* <div className="col-lg-6 mb-4">
                  <div className="cyan-box">
                    <div className="v-centered">



                      <h4 className="mb-4">Sale Details</h4>

                      <ul className="list-leftrightcontent">
                        <li>
                          <span>{idoData.type === "IDO" ? "Token Price" : "Total Invested"}</span>
                          {idoData.type === "IDO" ?
                            <span>{idoData.price} SOL</span>
                            :
                            <span>{totalInvested} SOL</span>
                          }
                        </li>
                        <li>
                          {idoData.type === "IDO" ?
                            <span>Rate per 1 SOL</span>
                            :
                            <span>Current Rate in SOL</span>
                          }
                          {idoData.type === "IDO" ?
                            <span>{1 / idoData.price} {idoData.ticker} / SOL</span>
                            :
                            <span>{(totalInvested / idoData.tokensale_supply).toFixed(10)} SOL</span>
                          }
                        </li>
                        <li>
                          {idoData.type === "IDO" ?
                            <>
                              <span>Maximum Buy</span>
                              <span>100 SOL</span>
                            </>
                            :
                            <>
                              <span>Minimum Buy</span>
                              <span>0.1 SOL</span>
                            </>
                          }
                        </li>
                      </ul>


                    </div>
                  </div>
                </div> */}




              </div>
            </div>
          </div>
        </main>
      </div>
















    </>

  );
};

export default AppEngine;
