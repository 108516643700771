import AppEngine from './AppEngine'


// To get IDO address from URL
function getIdoContractAddress() {
  if (window.location.href.indexOf("/A-") > 0) {
    var completeUrl = window.location.href;
    // "https://solana.mobipad.io/A-44MeHG1ZiZGkqiSXPPiYexHSAkmBR1mbSkucEQjycKBu-MBP"
    var idoAddrStr = completeUrl.substring(completeUrl.indexOf("/A-") + 1);
    idoAddrStr = idoAddrStr.substring(0, idoAddrStr.indexOf("-MBP") + 4);
    return idoAddrStr;
  } else {
    return "";
  }
}
function getDepositAddress() {
  if (window.location.href.indexOf("/A-") > 0) {
    var completeUrl = window.location.href;
    // "https://solana.mobipad.io/A-44MeHG1ZiZGkqiSXPPiYexHSAkmBR1mbSkucEQjycKBu-MBP"
    var depositAddrStr = completeUrl.substring(completeUrl.indexOf("/A-") + 3);
    depositAddrStr = depositAddrStr.substring(0, depositAddrStr.indexOf("-MBP"));
    return depositAddrStr;
  } else {
    return "";
  }
}

function App() {
  return (
    <AppEngine idoContractAddr={getIdoContractAddress()} depositAddrStr={getDepositAddress()} ></AppEngine>
  );
}

export default App;
